<template>
    <section>
        <div id="modal-center" class="uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog" style="max-height: 800px; overflow-y: scroll;">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal" />
                <div class="uk-modal-header uk-text-center">
                    <h2 class="uk-modal-title">{{ pageTitle ? pageTitle : 'Lampiran' }}</h2>
                </div>
                <div class="uk-modal-body">
                    <template v-if="filesUrl.length > 0">
                        <pdf v-for="(url, index) in filesUrl" :key="index" :src="url" style="margin-bottom: 10px;"/>
                    </template>
                    <div class="uk-width-1-1">
                        <div class="uk-flex uk-flex-middle uk-flex-center">
                            <button
                                type="button"
                                class="uk-button uk-button-primary uk-width-1-3 uk-border-rounded uk-position-relative"
                                @click="download()"
                            >
                                <img class="uk-position-center-left uk-margin-small-left" :src="`${images}/download-outline.svg`" alt="Download icon">
                                <span>Download</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        type="button"
                        class="uk-button uk-button-primary uk-modal-close"
                        @click="hideModal()"
                    >Close</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import pdf from 'vue-pdf';
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    components: { pdf },
    props: {
        pageTitle: {
            type: String,
            required: false,
        },
        filesUrl: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            // src: pdf.createLoadingTask(this.$props.url),
        };
    },
    mounted() {
        window.UIkit.modal('#modal-center').show();
    },
    methods: {
        hideModal() {
            window.UIkit.modal('#modal-center').$destroy(true);
            this.$emit("hideModal");
        },
        download() {
            window.UIkit.modal('#modal-center').$destroy(true);
            this.$emit("download-pdf");
        },
    }
};
</script>
